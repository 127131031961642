import React from 'react'
import './Footer.css'

function Footer() {
    const currentYear = new Date().getFullYear();
  return (
    <div className="footer-containter">
                <p>©{currentYear} Mel<span>o</span>dium - The SJEC Studio. All Rights Reserved | Developed by <a href="https://bit.ly/melroy-dsa">Melroy Dsa</a></p>
    </div>
  )
}

export default Footer