import React,{useEffect} from 'react';
import './BookingThree.css';
import TimeSlots from '../Mini/TimeSlots';
import { Icon } from '@iconify/react';
import BookingStage from '../Mini/BookingStage';
import {useLocation, useNavigate } from 'react-router-dom';
import melodiumlogo from '../../Images/MelodiumLogo.webp'
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
// import { encrypt} from '../../../api/util/encryptionUtils';


function generateRandomBookingNumber(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let bookingNumber = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    bookingNumber += characters.charAt(randomIndex);
  }

  return bookingNumber;
}



const API_BASE = process.env.REACT_APP_API_BASE;

function BookingThree() {
  const location = useLocation();
  const userDetails = location.state.Details;
  const Slots = location.state.Info;
  let day = Slots.day;
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Provide the actual bookingDate and Slots values
      deletedLocked(Slots.date, Slots.selectedTimeSlots);
  
      // Optionally, provide a confirmation message
      const confirmationMessage = "Are you sure you want to leave? Your changes may be lost.";
      event.returnValue = confirmationMessage; // Standard for most browsers
  
      // Use navigator.sendBeacon to send a synchronous request in the background
      navigator.sendBeacon('/', JSON.stringify({ bookingDate: Slots.date, slots: Slots.selectedTimeSlots }));
    };
  
    // Attach the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [Slots.date, Slots.selectedTimeSlots]); // Make sure to include all dependencies in the dependency array
  

    if(day==='Sunday')
    {
        day='Monday'
    }
    else
    if(day==='Monday')
    {
        day='Tuesday'
    }
    else
    if(day==='Tuesday')
    {
        day='Wednesday'
    }
    else
    if(day==='Wednesday')
    {
        day='Thursday'
    }
    else
    if(day==='Thursday')
    {
        day='Friday'
    }
    const addBooking = async (randomBookingNumber, Payment) => {
      try {
        // Booking Added Request
        const bookingResponse = await fetch(API_BASE + '/BookingAdded', {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            BookingID: randomBookingNumber,
            FirstName: userDetails.FirstName,
            LastName: userDetails.LastName,
            ContactNumber: userDetails.PhoneNumber,
            EmailAddress: userDetails.EmailAddress,
            TimeSlots: Slots.selectedTimeSlots,
            TypeOfSession: userDetails.Service,
            PaymentStatus: Payment,
            BookingDate: Slots.date,
            BookingDay: Slots.day
          })
        });
    
        // Check if Booking Added Request was successful
        if (!bookingResponse.ok) {
          throw new Error(`Error adding booking: ${bookingResponse.statusText}`);
        }
    
        // console.log('Preparing to send email...');
    
        // // Send Email Request
        // const emailResponse = await fetch(API_BASE + '/sendEmail', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({
        //     recipientEmail: userDetails.EmailAddress,
        //     FirstName: userDetails.FirstName,
        //     LastName: userDetails.LastName,
        //     Slots: Slots.selectedTimeSlots,
        //     TypeOfSession: userDetails.Service,
        //     BookingDate: Slots.date,
        //     BookingDay: Slots.day, // Ensure this is sent correctly
        //   }),
        // });
    
        // // Check if Send Email Request was successful
        // if (!emailResponse.ok) {
        //   throw new Error(`Error sending email: ${emailResponse.statusText}`);
        // }
        deletedLocked(Slots.date, Slots.selectedTimeSlots);
    
        console.log('Booking process completed.');
    
      } catch (error) {
        console.error('Error in booking process:', error);
        alert("There was an error processing the booking. Please try again.");
      }
    };
  const deletedLocked = (bookingDate,Slots) => {
    const apiEndpoint = API_BASE + '/deleteLocked';
    
    // Use the fetch function to send a DELETE request
    fetch(apiEndpoint, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        BookingDate: bookingDate,
        BookingSlot: Slots
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Handle success data if needed
    })
    .catch(error => {
      // Handle errors
      console.error('Error:', error);
    });
  };
  const checkBooking = async () => {
    return new Promise(async (resolve, reject) => {
      
      const requestBody = {
        BookingDate: Slots.date, // Replace with your desired date
        BookingSlot: Slots.selectedTimeSlots // Replace with your desired slot
      };
      try {
        const response = await fetch(API_BASE+'/FindBooking', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody)
        });
  
        const result = await response.json();
        if (result.found) {
          resolve(true); // Resolve with true if booking exists
        } else {
          resolve(false); // Resolve with false if booking does not exist
        }
      } catch (error) {
        console.error("Error:", error);
        reject(error); // Reject with the error if there is an issue
      }
    });
  };
  
  const navigate = useNavigate();
  const bookingDets = () =>
  {
    
    const randomBookingNumber = generateRandomBookingNumber(8); 
    var Payment;
    if(userDetails.Service === 'Jam Room')
    {
        Payment = "Nil"
    }
    else
    {
      Payment = "Not Paid"
    }
    checkBooking().then((res) => {
      if (res === true) {
        deletedLocked(Slots.date, Slots.selectedTimeSlots);
        var Room = 'Studio'
        if(Slots.TypeOfSession==='Jam Room')
          Room = 'Jam Room'
        toast("Session has already been booked", {
          position: "top-left",
          autoClose: 2000, // Adjust as needed
          type: "success", // or "info", "warning", "error"
        });
        setTimeout(() => {
          navigate("/BookingTimeSlots", { state: { Type: Room } });
        }, 2000);
      } else {
        addBooking(randomBookingNumber, Payment);
        navigate("/ConfirmedBooking", { state: { BookingID: randomBookingNumber } });
      }
    });
    
  }
  const handleBack =() =>
  {
    var Room = 'Studio'
        if(Slots.TypeOfSession==='Jam Room')
          Room = 'Jam Room'
    navigate("/BookingDetails", { state: { DateAndTime: Slots,Type:Room }});
  }
  

  return (
    <>
    <Icon icon="material-symbols:arrow-back" id='back-arrow' onClick={handleBack}/>
    <BookingStage stage={3} />
    <div className="BookingThreeContainer">
      
      <div className="DisplayContainer">
        <img class="melodium-logo-confirm" src={melodiumlogo} alt="" />
        <h1>Name:</h1>
        <h1>Contact Number:</h1>
        <h1 id="FirstName">{userDetails.FirstName} {userDetails.LastName}</h1>
        <h1>{userDetails.PhoneNumber}</h1>
        <h1>Email:</h1>
        <h1>Type of Session:</h1>
        <h1>{userDetails.EmailAddress}</h1>
        <h1>{userDetails.Service}</h1>
        <h1>Slots:</h1>
        <h1>{Slots.date}</h1>
        <div className="slots-confirm-container">
          <div className="TimeSlotContainer">
            {Slots.selectedTimeSlots.map((slotValue, index) => (
              <TimeSlots className="TimeSlot" key={index} SlotValue={slotValue} />
            ))}
          </div>
          <button className='ConfirmButton' onClick={bookingDets}>Confirm</button>
        </div>
        <ToastContainer/>
       </div>
       
      
      </div>
    </>
  );
}

export default BookingThree;
